// src/hooks/useReviewForm.js
import { useState } from "react";

const useReviewForm = (initialState, validateReview, ignoreSoftWarnings) => {
    const [formData, setFormData] = useState(initialState);
    const [formErrors, setFormErrors] = useState({});
    const [formWarnings, setFormWarnings] = useState([]);

    const validateReviewForm = () => {
        let validationResults = {};

        if (typeof validateReview === "function") {
            // Safely call the validate function if it's provided and is a function
            validationResults = validateReview(formData, ignoreSoftWarnings);
        }

        const { errors = {}, warnings = [] } = validationResults;

        // Update state only if there are changes in validation errors
        if (JSON.stringify(formErrors) !== JSON.stringify(errors)) {
            setFormErrors(errors);
        }

        // Update warnings state only if there are changes
        if (JSON.stringify(formWarnings) !== JSON.stringify(warnings)) {
            setFormWarnings(warnings);
        }

        // Form is considered invalid if there are any errors or warnings
        const isFormValid = Object.keys(errors).length === 0 && warnings.length === 0;
        // Return the validity status along with errors and warnings
        return { isFormValid, errors, warnings };
    };

    // Return the form state, setters, and validation function
    return { formData, setFormData, formErrors, formWarnings, validateReviewForm };
};

export default useReviewForm;
