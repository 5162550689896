// src/components/review/LabCultureSection.jsx
import React from 'react';
import TwoWaySlider from '../common/TwoWaySlider';
import sliderConfig from '../../config/sliderConfig';


const LabCultureSection = ({ formData, handleChange }) => {
    const labCultureSliders = [
        "teamOrientationLevel",
        "respectInclusivityLevel",
        "workLifeBalanceLevel",
        "diversityInclusionLevel",
        "socialCohesionLevel",
    ];

    return (
        <section>
            {labCultureSliders.map(key => {
                const config = sliderConfig[key];
                if (!config) {
                    console.warn(`Configuration for "${key}" not found in sliderConfig.`);
                    return null;
                }

                // Accessing nested slider values from formData.attributes.sliders
                const sliderValue = formData.attributes.sliders[key] || 0; // Fallback to min value if undefined

                return (
                    <TwoWaySlider
                        key={key}
                        labelLeft={config.labelLeft}
                        labelRight={config.labelRight}
                        min={config.min}
                        max={config.max}
                        name={key}
                        value={sliderValue}
                        onChange={(e) => handleChange({
                            target: {
                                name: `attributes.sliders.${key}`,
                                value: e.target.value
                            }
                        })}
                    />
                );
            })}
        </section>
    );
};

export default LabCultureSection;
