// src/components/widgets/LoadingComponent.jsx
import React from 'react';
import styles from './LoadingComponent.module.css';

const LoadingComponent = () => {
  // Function to generate a random color
  // const getRandomColor = () => {
  //   const r = Math.floor(Math.random() * 256); // Red value between 0-255
  //   const g = Math.floor(Math.random() * 256); // Green value between 0-255
  //   const b = Math.floor(Math.random() * 256); // Blue value between 0-255
  //   return `rgb(${r}, ${g}, ${b})`; // Return the RGB color string
  // };
  //const randomColor = getRandomColor(); // Get a random color

  return (
    <div className={styles.container} style={{ backgroundColor: 'var(--background-color)' }}>
      {/* Additional loading UI elements can go here if needed */}
    </div>
  );
};

export default LoadingComponent;
