/**
 * src/django_api/authService.js
 * (Backend Interaction, Token Management, and Session Handling)
 *
 * Role: Manages interactions with the backend for django_authentication processes, handles JWT token management,
 * and abstracts technical details of session and token operations.
 *
 * Key Responsibilities:
 * - OAuth Process Initiation: Initiates the OAuth login process, including generating a unique state value and redirecting the user to the OAuth endpoint.
 * - JWT Token Management: Handles the retrieval, storage, validation, and refresh of JWT tokens.
 * - Session Status Check: Provides functions to check and validate the current session's status, based on the presence and validity of JWT tokens.
 * - Logout Process: Manages the logout process, including communication with the backend for session termination and clearing tokens from client-side storage.
 * - Error Handling: Manages error scenarios that occur during backend interactions and token handling processes.
 *
 * Usage Scenarios:
 * - Starting the Login Process: Used when a user initiates the login via OAuth.
 * - Session and Token Validation: Invoked for validating the current session and handling token refresh processes, typically by functions within AuthContext.
 * - Performing Logout: Handles the complete logout process, including backend communication and client-side token removal.
 */
// src/django_api/authService.js
import { privateAxiosInstance } from './axios';
import { backendURL } from '../config';


// Error Handling
const handleError = (error, message) => {
    console.error(message, error);
    return {success: false, error: `${message}: ${error.message}`};
};


const authService = {
    // Sets idToken in sessionStorage
    setIdToken: (idToken) => {
        console.log("Storing idToken in sessionStorage");
        sessionStorage.setItem('idToken', idToken);
    },

    // Retrieves idToken from sessionStorage
    getIdToken: () => {
        // console.log("Getting idToken in sessionStorage");
        return sessionStorage.getItem('idToken');
    },

    // Clears idToken from session storage on logout
    logout: () => {
        console.log("Removing idToken in sessionStorage");
        sessionStorage.removeItem('idToken');
    },

    // Sends idToken to backend for verification
    verifyIdTokenWithBackend: async (idToken) => {
        // console.log("Sending token to backend for verification:", idToken);
        try {
            const response = await privateAxiosInstance.post(`${backendURL}/api/v1/verify-token/`, {token: idToken});
            return {success: true, data: response.data};
        } catch (error) {
            return handleError(error, 'Error verifying idToken with backend');
        }
    },
};

export default authService;


/* Function to validate JWT and update state
// Function to send idToken to backend for verification
const verifyIdTokenWithBackend = async (idToken) => {
    try {
        const response = await axiosInstance.post(`${backendBaseURL}/auth/verify-google-token/`, { idToken });
        return { success: true, data: response.data };
    } catch (error) {
        return handleError(error, 'Error verifying idToken with backend');
    }
};

// User Logout
const logout = () => {
};


// Service Exports
const authService = {
    verifyIdTokenWithBackend,
    logout,
    // Additional methods if needed
};

export default authService;


/* REMOVED JWT / SESSION FUNCTIONALITY
import { jwtDecode } from "jwt-decode";
import {generateUniqueStateValue} from "../utils/stateUtils";

// Session Storage Management
const setSessionItem = (key, value) => sessionStorage.setItem(key, value);
const getSessionItem = (key) => sessionStorage.getItem(key);
const removeSessionItem = (key) => sessionStorage.removeItem(key);

// Token Handling
const tokenUtils = {
    getToken: () => getSessionItem('jwtToken'),
    getRefreshToken: () => getSessionItem('refreshToken'),
    setTokens: ({ access, refresh }) => {
        setSessionItem('jwtToken', access);
        setSessionItem('refreshToken', refresh);
    },
    removeTokens: () => {
        removeSessionItem('jwtToken');
        removeSessionItem('refreshToken');
    },
};




// Authentication State Initialization
const initializeAuthState = () => {
    const storedState = getSessionItem('authState');
    return storedState ? JSON.parse(storedState) : defaultAuthState;
};

const defaultAuthState = { isAuthorized: false, schoolAffiliation: null, loading: true, error: null };

// const getAuthState = () => {
//     const storedState = getSessionItem('authState');
//     return storedState ? JSON.parse(storedState) : { isAuthorized: false, schoolAffiliation: null, loading: true, error: null };
// };



// OAuth Process Initiation
const startOAuth = () => {
    const stateValue = generateUniqueStateValue();
    setSessionItem('oauth_state', stateValue);
    window.location.href = `${backendBaseURL}/auth/login/google-oauth2/?state=${stateValue}`;
};


// JWT Token Fetching
const fetchJWTToken = async () => {
    try {
        const response = await axiosInstance.get('/auth/django_api/get-jwt-token/', { withCredentials: true });
        tokenUtils.setTokens(response.data);    // Save tokens using the helper function
        return { success: true };       // Indicate successful token retrieval
    } catch (error) {
        return handleError(error, 'Error fetching JWT token');
    }
};

// JWT Token Refresh
const refreshJWTToken = async () => {
    try {
        const refreshToken = tokenUtils.getRefreshToken();
        const response = await axiosInstance.post('/auth/django_api/refresh-jwt-token/', {refresh: refreshToken});
        tokenUtils.setTokens(response.data);
        return { success: true };
    } catch (error) {
        tokenUtils.removeTokens();
        return handleError(error, 'Error refreshing JWT token');
    }
};

// JWT Validation
const validateJWT = () => {
    const jwtToken = tokenUtils.getToken();
    if (!jwtToken) return { isValid: false };

    try {
        const decodedToken = jwtDecode(jwtToken);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) return { isValid: false };
        return { isValid: true, user: decodedToken.user_id, school: decodedToken.school };
    } catch (error) {
        return handleError(error, 'Error decoding JWT token');
    }
};

// User Logout
const logout = async () => {
    tokenUtils.removeTokens();
};

// Service Exports
const authService = {
    getAuthState: initializeAuthState,
    startOAuth,
    fetchJWTToken,
    refreshJWTToken,
    validateJWT,
    logout,
    verifyIdTokenWithBackend,
    // Utility methods
    ...tokenUtils,
    // Additional methods if needed
};

export default authService;

 */