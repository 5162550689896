// src/components/navbar/navbar.jsx
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './NavBar.module.css';
import { AuthContext } from '../../contexts/AuthContext';
import OAuthLoginButton from "./OAuthLoginButton";
import OAuthLogOutButton from "./OAuthLogOutButton";
import DashboardButton from "./DashboardButton";
import ThemeToggle from './ThemeToggle';

const NavBar = () => {
    const { authState } = useContext(AuthContext);

    return (
        <header className={styles.header}>
            <nav className={styles['nav-bar']}>
                <div className={styles['title-container']}>
                   <Link to="/" className={`${styles.title} formal`}>Mentorship Index</Link>
                </div>
                <div className={styles['button-container']}>
                    <ThemeToggle />
                    {!authState.isAuthorized && <OAuthLoginButton />}
                    {authState.isAuthorized && (
                        <>
                            <DashboardButton />
                            <OAuthLogOutButton />
                        </>
                    )}
                </div>
            </nav>
        </header>
    );
};

export default NavBar;