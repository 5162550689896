// src/components/review/AccessibilitySection.jsx
import React from 'react';
import TwoWaySlider from '../common/TwoWaySlider';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import sliderConfig from '../../config/sliderConfig';


const AccessibilitySection = ({ formData, handleChange }) => {

    // Define the options for meeting frequency
    const meetingFrequencyOptions = [
        {value: 'MORE_THAN_WEEKLY', label: 'More than weekly / open-door policy'},
        {value: 'WEEKLY', label: 'Weekly'},
        {value: 'BIWEEKLY', label: 'Biweekly'},
        {value: 'MONTHLY', label: 'Monthly'},
        {value: 'LESS_THAN_MONTHLY', label: 'Less than monthly'},
        {value: 'NOT_DIRECT', label: 'I don’t directly work with my advisor'}
    ];

    return (
        <section>
            <FormControl component="fieldset">
                <FormLabel component="legend">Meeting Frequency</FormLabel>
                <RadioGroup
                    aria-label="meeting-frequency"
                    name="attributes.meetingFrequency.value"
                    value={formData.attributes.meetingFrequency.value}
                    onChange={handleChange}
                >
                    {meetingFrequencyOptions.map(option => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio/>}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            </FormControl>

            <TwoWaySlider
                labelLeft={sliderConfig.accessibility.labelLeft}
                labelRight={sliderConfig.accessibility.labelRight}
                min={sliderConfig.accessibility.min}
                max={sliderConfig.accessibility.max}
                name="attributes.sliders.accessibility"
                value={formData.attributes.sliders.accessibility}
                onChange={handleChange}
            />
            <TwoWaySlider
                labelLeft={sliderConfig.responseTime.labelLeft}
                labelRight={sliderConfig.responseTime.labelRight}
                min={sliderConfig.responseTime.min}
                max={sliderConfig.responseTime.max}
                name="attributes.sliders.responseTime"
                value={formData.attributes.sliders.responseTime}
                onChange={handleChange}
            />
        </section>
    );
};

export default AccessibilitySection;
