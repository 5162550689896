// src\components\navbar\OAuthLoginButton.jsx
import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import styles from './OAuthLoginButton.module.css';
import useGoogleAuth from '../../hooks/useGoogleAuth';
import { googleClientID } from '../../config';
import { eventEmitter } from '../../utils/eventEmitter';


const OAuthLoginButton = forwardRef((props, ref) => {
    const { signIn, error, handleCredentialResponse } = useGoogleAuth();
    const [googleApiLoaded, setGoogleApiLoaded] = useState(false);

    // Load Google API Script
    useEffect(() => {
        const scriptId = 'google-auth-script';
        const loadGoogleApi = () => {
            // Check if the script tag already exists
            if (!document.getElementById(scriptId)) {
                // If not, create and append the script tag
                const script = document.createElement('script');
                script.id = scriptId;
                script.src = 'https://accounts.google.com/gsi/client';
                script.onload = () => {
                    // console.log("Google API script loaded");
                    setGoogleApiLoaded(true);
                };
                document.body.appendChild(script);
            } else {
                // If the script tag exists, check if the Google API is already available
                if (window.google && window.google.accounts) {
                    console.log("Google API already loaded");
                    setGoogleApiLoaded(true);
                }
            }
        };

        loadGoogleApi();
    }, []);

    // if loaded, Initialize Google API script
    useEffect(() => {
        if (googleApiLoaded) {
            // console.log("Initializing Google Sign-In");
            window.google.accounts.id.initialize({
                client_id: googleClientID, // Use client_id here
                callback: handleCredentialResponse,
            });
            // console.log("Rendering Google Sign-In button");
            window.google.accounts.id.renderButton(
                document.getElementById('oauth-button'),
                {shape: 'pill', theme: 'outline', text: 'signin', size: 'large'}
            );
        }
    }, [googleApiLoaded, handleCredentialResponse]);

    // Effect to handle error changes
    useEffect(() => {
        if (error) {
            // Emit an error event instead of displaying it directly
            eventEmitter.emitError('errorMessage', error);
        }
    }, [error]); // Add error as a dependency so this effect runs when error changes


    // Expose signIn method to parent components
    useImperativeHandle(ref, () => ({
        triggerSignIn: () => {
            signIn();
        }
    }));

    return (
        <div className={styles['button-wrapper']}>
            <div id="oauth-button" onClick={signIn}></div>
        </div>
    );
});

export default OAuthLoginButton;
