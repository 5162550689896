// src/components/SearchBar.jsx
import React, { useState, useEffect } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { Link } from 'react-router-dom';
import styles from './SearchBar.module.css';
import { publicAxiosInstance } from '../../api/axios';

function SearchBar() {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);

  // Create a debounced version of the search query
  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    // Early exit if the query is empty
    if (!debouncedQuery) {
      setSearchResults(null);
      return;
    }

    // Flag to indicate searching status, no need for separate state
    //const isSearching = true;

    const performSearch = async () => {
      try {
        const response = await publicAxiosInstance.get(`/api/v1/advisors/?search=${encodeURIComponent(debouncedQuery)}`);
        setSearchResults(response.data);
      } catch (error) {
        console.error('Error searching for advisors:', error);
        // Optionally update the UI to reflect the error
      }
    };

    performSearch();
  }, [debouncedQuery]); // Dependency array ensures this effect runs only when debouncedQuery changes


  return (
    <div className={styles['search-container']}>
      <form id="search-form" role="search" onSubmit={(e) => e.preventDefault()} className={styles['search-form']}>
        <input
          type="search"
          id="advisor-search"
          name="advisorSearch"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for advisors by name"
          aria-label="Search Advisors"
          className={styles['search-input']}
        />
        {/* Conditional rendering based on whether searchResults is null */}
        {searchResults && (
          <div className={styles['search-results']}>
            {searchResults.length > 0 ? (
              <ul className={styles['results-list']}>
                {searchResults.map((advisor) => (
                  <li key={advisor.id} className={styles['result-item']}>
                    <Link to={`/advisor/${advisor.id}`} className={styles['result-link']}>
                      {advisor.name}, <i>{advisor.school_name}</i>
                    </Link>
                </li>
              ))}
            </ul>
          ) : (
            <div className={styles['no-results']}>No results found.
            {/* TODO: update to add at the bottom of every search, "Alert Us to Missing Advisor" button to email us with their search term */}
            </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
}

export default SearchBar;