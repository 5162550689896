// src/validations/reviewValidation.js

// Helper functions
const isSliderChanged = (value, defaultSliderValue = 0) => value !== defaultSliderValue;
const isRadioButtonSelected = (value) => ['YES', 'NO', 'UNSURE'].includes(value.value);
const isStringFieldFilled = (value) => typeof value === 'string' && value.trim() !== '';


export const validate = (reviewFormData, ignoreSoftWarnings) => {
    const errors = {};
    const warnings = [];

    const { attributes } = reviewFormData;

    console.log("Validating Form State:", reviewFormData); // TODO: for debugging purposes

    // Adjusted to check sliders within attributes.sliders
    const isAnySliderChanged = (sliders) => Object.values(sliders).some(slider => isSliderChanged(slider.value));


    // Check if at least one feedback field has changed
    const hasFeedbackChanged = Object.entries(attributes).some(([key, value]) => {
        switch (key) {
            case 'sliders':
                return isAnySliderChanged(value);
            case 'additionalComments':
                return isStringFieldFilled(value);
            default:
                return isRadioButtonSelected(value);
        }
    });

    // Add general feedback error if no feedback is provided
    if (!hasFeedbackChanged) {
        errors.general = 'Please provide feedback on at least one aspect of your advisor.';
    }

    if (attributes.additionalComments && attributes.additionalComments.length > 1000) {
        errors.additionalComments = 'Comments should be less than 1000 characters.';
    }

    // Ensure a position is selected
    if (!['UNDERGRADUATE', 'GRADUATE', 'POST_BAC', 'POST_DOC', 'LAB_TECH'].includes(attributes.positionHeld.value)) {
        errors.positionHeld = 'Please select the position you held while working with this advisor.';
    }

    // Optionally handle soft warnings
    if (!ignoreSoftWarnings) {
        // Check for potential contradictions or notable conditions in the feedback
        if (attributes.meetingFrequency === 'LTM' && attributes.accessibility > 5) {
            warnings.push('Accessibility rating is high despite infrequent meetings. Please review your responses.');
        }
    }

    return { errors, warnings };
};
