// src/components/footer/footer.jsx
import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
        <p>
            Your privacy is our priority.<br/>
            Reviews are anonymous and this site collects no personal information.<br/><br/>
            Built by Kavan + Nancy, with assistance from ChatGPT.
        </p>
    </footer>
  );
};

export default Footer;
