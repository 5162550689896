// src/utils/eventEmitter.js
import { EventEmitter } from 'events';

class DebugEventEmitter extends EventEmitter {

    emitError(event, error) {
        const errorMessage = (error instanceof Error) ? error.message : error;
        this.emit(event, {text: errorMessage || 'No error message available'});
    }

    emitMessage(event, text) {
        this.emit(event, { text });
    }

    emit(event, ...args) {
        console.log(`Event emitted: ${event}`, ...args);  // Log the event and its arguments for debugging
        //console.trace(`Stack trace for event: ${event}`);  // Capture the stack trace and log it
        return super.emit(event, ...args);  // Call the original EventEmitter's emit method
    }
}

// Use the DebugEventEmitter instead of the default EventEmitter
export const eventEmitter = new DebugEventEmitter();
