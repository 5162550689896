// src/utils/lookupTables.js
const lookupTables = {
    graduateOnTime: {
        YES: "Yes",
        NO: "No",
        UNSURE: "Unsure/Unknown",
    },
    recommendAdvisor: {
        YES: "Yes",
        NO: "No",
        UNSURE: "Unsure/Unknown",
    },
    positionHeld: {
        UNDERGRADUATE: "Undergraduate",
        POST_BAC: "Post-bac",
        GRADUATE: "Graduate",
        POST_DOC: "Post-doc",
        LAB_TECH: "Lab tech",
    },
    meetingFrequency: {
        MORE_THAN_WEEKLY: "More than weekly / open-door policy",
        WEEKLY: "Weekly",
        BIWEEKLY: "Biweekly",
        MONTHLY: "Monthly",
        LESS_THAN_MONTHLY: "Less than monthly",
        NOT_DIRECT: "I don’t directly work with my advisor",
    },
    statusEnum: {
        PENDING: "Pending",
        APPROVED: "Approved",
        REJECTED: "Rejected",
    },
};

export default lookupTables;
