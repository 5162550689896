// src/components/common/CheckboxGroup.jsx
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './SharedFormStyles.module.css';

const Checkbox = ({ name, optionText, isChecked, onChange }) => (
    <div>
        <input
            type="checkbox"
            id={`${name}-${optionText}`}
            name={name}
            value={optionText}
            checked={isChecked}
            onChange={(e) => onChange(e)}
            aria-labelledby={`${name}-label-${optionText}`}
        />
        <label id={`${name}-label-${optionText}`} htmlFor={`${name}-${optionText}`}>{optionText}</label>
    </div>
);

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    optionText: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

const CheckboxGroup = ({ category = {}, onChange, state = {}, error, warning, className = '' }) => {
    const { name, question, options } = category;

    const initialCheckboxStatus = useMemo(() => options.reduce((status, optionText) => {
        status[optionText] = state[name]?.includes(optionText) || false;
        return status;
    }, {}), [options, name, state]);

    if (!name || !question || !options || options.length === 0) {
        return <p>No checkboxes to display.</p>;
    }

    let groupClassName = `${className} ${error ? styles['form-field-error'] : warning ? styles['form-field-warning'] : ''}`.trim();

    return (
        <fieldset className={groupClassName}>
            <legend>{question}</legend>
            {options.map(optionText => (
                <Checkbox
                    key={`${name}-${optionText}`}
                    name={name}
                    optionText={optionText}
                    isChecked={initialCheckboxStatus[optionText]}
                    onChange={(e) => onChange(e, name, optionText)}
                />
            ))}
            {error && <p className={styles['error-message']}>{error}</p>}
            {warning && <p className={styles['warning-message']}>{warning}</p>}
        </fieldset>
    );
};

CheckboxGroup.defaultProps = {
    category: {},
    state: {},
};

CheckboxGroup.propTypes = {
    category: PropTypes.shape({
        name: PropTypes.string.isRequired,
        question: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    state: PropTypes.object,
    error: PropTypes.string,
    warning: PropTypes.string,
    className: PropTypes.string,
};

export default CheckboxGroup;
