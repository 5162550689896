// TwoWaySlider.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styles from './TwoWaySlider.module.css';
import { alpha } from '@mui/material/styles';

const TwoWaySlider = ({ name, min, max, value, onChange, labelLeft, labelRight, disabled = false, readOnly = false }) => {
    const handleSliderChange = (event) => {
        if (!readOnly && !disabled && onChange) {
            onChange({
                target: {
                    name: name,
                    value: Number(event.target.value),
                },
            });
        }
    };

    const getSliderBackgroundGradient = () => {
        const percentage = ((value - min) / (max - min)) * 100;
        const midpoint = 50;
        const positiveColor = 'var(--slider-value-color, #007bff)';
        const trackColor = 'var(--slider-track-color, #d3d3d3)';

        // Adjust the gradient to fill from the center outwards
        return value >= 0
            ? `linear-gradient(to right,
                    ${trackColor} ${midpoint}%, 
                    ${positiveColor} ${midpoint}%, 
                    ${positiveColor} ${percentage}%, 
                    ${trackColor} ${percentage}%)`
            : `linear-gradient(to right, 
                    ${trackColor} ${percentage}%, 
                    ${positiveColor} ${percentage}%, 
                    ${positiveColor} ${midpoint}%, 
                    ${trackColor} ${midpoint}%)`;
    };

    const sliderBackground = getSliderBackgroundGradient();

    const sliderStyle = {
        background: sliderBackground,
        cursor: readOnly ? 'default' : 'pointer',
        ...(disabled && {
            background: alpha(sliderBackground, 0.5),
            cursor: 'not-allowed',
        }),
    };

    return (
        <div className={styles.sliderContainer}>
            <input
                type="range"
                name={name}
                min={min}
                max={max}
                value={value}
                onChange={handleSliderChange}
                className={`${styles.slider} ${readOnly ? styles.readOnlySlider : ''}`}
                style={sliderStyle}
                disabled={disabled}
                aria-label={`${labelLeft} to ${labelRight}`}
            />
            <div className={styles.sliderLabels}>
                <span className={styles.labelLeft}>{labelLeft}</span>
                <span className={styles.labelRight}>{labelRight}</span>
            </div>
        </div>
    );
};

TwoWaySlider.propTypes = {
    name: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    labelLeft: PropTypes.string,
    labelRight: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

export default TwoWaySlider;
