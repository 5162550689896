// src/pages/Home.jsx
import React from 'react';
import SearchBar from '../components/search/SearchBar';
import MessageDisplay from '../components/messages/MessageDisplay';
import styles from './Home.module.css';
import { versionNumber } from '../config';
import { Typography, Box } from '@mui/material';

const Home = () => {
    return (
        <main className='page-container'>
            <Box className={styles.hero}>
                <Typography
                    variant="h6"
                    component="p"
                    sx={{
                       fontSize: {
                         lg: '1.3rem',  // Large devices
                         md: '1.3rem',  // Medium devices
                         sm: '1.1rem',  // Small devices
                         xs: '1rem'     // Tiny devices
                       },
                        textAlign: 'center',
                        width: '100%',
                        mx: 'auto' // shorthand for margin left and right auto
                    }}
                >
                    Choosing an academic advisors isn't easy, additional datapoints can help.
                </Typography>
            </Box>
            <MessageDisplay />
            <SearchBar /> {/* TODO: update to add at the bottom of every search, "Alert Us to Missing Advisor" email us with their search term */}
            <Box className={`${styles['call-to-action']}`}>
                <Typography variant="body1">
                    <i>How often did this advisor meet? What is the lab culture like?</i><br /><br />
                    Leave thoughtful reviews, share your experiences with those who will come after.<br /><br />
                    Anyone can browse reviews, just search for an advisor by name.<br /><br />
                    To review advisors at your school, sign in using your institutional email.
                </Typography>
            </Box>
            <Box className={`${styles['post-it']}`}>
                <Typography sx={{fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace" }} gutterBottom>
                    If you've come across this site, it is a pre-release build and isn't fully functional.
                    <br /><br />
                    v1:
                    <br />
                    - University of Washington (UW) via uw.edu email addresses.
                    <br />
                    - basic review functionality.
                    <br /><br />
                    hello@mentorshipindex.com
                </Typography>
                <Typography display="block" sx={{ position: 'absolute', bottom: '10px', right: '15px', fontSize: '0.75rem' }}>
                    <em>IN ACTIVE DEVELOPMENT - 2.2024 {versionNumber}</em>
                </Typography>
            </Box>
        </main>
    );
};

export default Home;
