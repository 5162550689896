// src/pages/SchoolProfile.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { publicAxiosInstance } from '../api/axios';
import LoadingComponent from "../components/widgets/LoadingComponent";
import SchoolInfo from '../components/SchoolInfo';


function SchoolProfile() {
  const {schoolId} = useParams();
  const [school, setSchool] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    async function fetchSchoolData() {
      setIsLoading(true);
      try {
        const response = await publicAxiosInstance.get(`/api/v1/schools/${schoolId}/`);
        setSchool(response.data);
      } catch (error) {
        console.error("Could not fetch school data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchSchoolData();
  }, [schoolId]);

  if (isLoading) return <LoadingComponent/>;
  if (!school) return <div>School not found.</div>;

  return (
      <div className='page-container'>
        <SchoolInfo
            schoolName={school.name}
            advisors={school.advisors || []}
        />
      </div>
  );
}

export default SchoolProfile;
