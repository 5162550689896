// src/pages/Review.jsx
// React and Related Libraries
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// API and Axios Instances
import { privateAxiosInstance } from "../api/axios";
// Hooks
import useReviewForm from '../hooks/useReviewForm';
// Components
import Dropdown from '../components/common/Dropdown';
import CheckboxGroup from '../components/common/CheckboxGroup';
import RadioButtonGroup from '../components/common/RadioButtonGroup';
import Textarea from '../components/common/Textarea';
// Configurations
import { checkboxCategories, radioCategories, dropdownCategories } from '../config/formOptions';
// Utilities
import { convertToSnakeCase, convertToCamelCase } from '../utils/caseConverter';
import { eventEmitter } from '../utils/eventEmitter';
// Validations
import { validate } from '../validations/reviewValidation';


function handleErrors(error) {
    console.error('Error:', error); // TODO: remove later

    let errorMessage = 'An error occurred while submitting your review.';
    if (error.response) {
        errorMessage = error.response.data.detail ||
                       error.response.data.error ||
                       'Internal server error. Please try again later.';
    } else if (error.request) {
        errorMessage = 'No response from the server. Please check your network connection.';
    } else {
        errorMessage = `Request setup error: ${error.message}`;
    }

    // Log more details for debugging purposes
    console.error('Error details:', {
        data: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers,
        request: error.request,
        message: error.message
    });

    return errorMessage;
}


const Review = () => {
    const { advisorId } = useParams();
    const [isEditing, setIsEditing] = useState(false);
    const [reviewId, setReviewId] = useState(null);

    const initialFormState = {
        meetingFrequency: null,
        advisorExperience: [],
        advisorStrengths: [],
        advisorWeaknesses: [],
        labCulture: [],
        graduateOnTime: null,
        recommendAdvisor: null,
        additionalComments: '',
    };

    const [ignoreSoftWarnings, setIgnoreSoftWarnings] = useState(false);

    // useReviewForm hook with initial state and validation function
    const {
        formData: reviewFormData,
        setFormData: setReviewFormData,
        formErrors,
        formWarnings,
        validateReviewForm
    } = useReviewForm(initialFormState, validate, ignoreSoftWarnings);

    // const [qrCodeDataUri, setQrCodeDataUri] = useState(''); // State to hold the QR code data URI

    const handleChange = (e) => {
        const {name, value, type} = e.target;
        let finalValue = value;

        // Specifically handle dropdowns to convert empty string to null
        if (name === 'meetingFrequency' && value === '') {
            finalValue = null;
        } else if (type === 'select-one' && value === '') {
            finalValue = null;
        } else if (type === "radio") {
            // For radio buttons, convert the value to a boolean or null
            finalValue = value === 'true' ? true : (value === 'false' ? false : null);
        }

        setReviewFormData(prevFormData => {
            // Log the updated form data inside the setState callback
            const updatedFormData = {...prevFormData, [name]: finalValue};
            console.log("Updated Form Data:", updatedFormData); // TODO: remove later
            return updatedFormData;
        });
    }

    const handleCheckboxChange = (checkboxName) => (event) => {
        const { value } = event.target;
        setReviewFormData(prevFormData => {
            const updatedValues = prevFormData[checkboxName].includes(value)
                ? prevFormData[checkboxName].filter(item => item !== value)
                : [...prevFormData[checkboxName], value];

            return { ...prevFormData, [checkboxName]: updatedValues };
        });
        console.log("Updated Checkbox State:", reviewFormData[checkboxName]); // potentially misleading because setReviewFormData does not immediately update the state TODO: remove later
    };

    // const downloadQrCode = () => {
    //     const link = document.createElement('a');
    //     link.href = qrCodeDataUri;
    //     link.download = `QRCode-${advisorId}.png`; // Filename for the downloaded image
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };

    // Load existing review data if editing
    useEffect(() => {
        const fetchReviewData = async () => {
            try {
                // Fetch reviews for the logged-in user and the specific advisor
                const response = await privateAxiosInstance.get(`/api/v1/reviews/user-reviews/?advisor_id=${advisorId}`);
                console.log("Fetched reviews:", response.data);  // Log to check fetched data// TODO: remove later
                const userReview  = response.data;
                if (userReview.length > 0) {
                    // Assuming the first review is the one we want to edit
                    const reviewToEdit = userReview[0];
                    const formattedReview = convertToCamelCase(reviewToEdit);
                    console.log("Review to edit:", formattedReview);  // Log to check the selected review// TODO: remove later
                    setReviewFormData(formattedReview);
                    setIsEditing(true);
                    setReviewId(formattedReview.id);
                }
            } catch (error) {
                console.error('Error fetching review data:', error);
                // Handle error (e.g., show error message or redirect)
            }
        };

        fetchReviewData();
    }, [advisorId, setReviewFormData]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("Attempting to Submit Form:", reviewFormData); // TODO: remove later
        const {isFormValid, errors, warnings} = validateReviewForm();
        console.log("Is Form Valid:", isFormValid); // TODO: remove later

        if (!isFormValid) {
            // Log and display all validation errors
            if (Object.keys(errors).length > 0) {
                console.log('Validation failed with errors:', errors);
                Object.keys(errors).forEach(key => {
                    eventEmitter.emitError('errorMessage', errors[key]);
                });
            }

            // Emit warnings if there are any and if they haven't been ignored yet
            if (warnings.length > 0 && !ignoreSoftWarnings) {
                warnings.forEach(warning => eventEmitter.emitMessage('warningMessage', warning));
                setIgnoreSoftWarnings(true);
            }

            // Prevent form submission if there are any errors or warnings
            return;
        }

        const dataForSubmission = convertToSnakeCase({advisor: advisorId, ...reviewFormData});
        console.log('Submitting review with data:', dataForSubmission); // TODO: remove later

        try {
            let response;
            if (isEditing) {
                response = await privateAxiosInstance.put(`/api/v1/reviews/${reviewId}`, dataForSubmission);
            } else {
                response = await privateAxiosInstance.post(`/api/v1/reviews/`, dataForSubmission);
            }

            console.log(response.data); // TODO: remove later
            // setQrCodeDataUri(response.data.qr_code);
            eventEmitter.emitMessage('successMessage', `Review ${isEditing ? 'updated' : 'submitted'} successfully!`);
            setIgnoreSoftWarnings(false); // Reset ignoreSoftWarnings after successful submission
            // Additional success handling...
        } catch (error) {
            const errorMessage = handleErrors(error);
            eventEmitter.emitError('apiError', new Error(errorMessage));
        }
    }

    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this review?')) return;

        try {
            await privateAxiosInstance.delete(`/api/v1/reviews/${reviewId}`);
            eventEmitter.emitMessage('successMessage', 'Review deleted successfully!');
        } catch (error) {
            const message = error.response?.data?.error || 'Failed to delete the review.';
            eventEmitter.emitError('apiError', new Error(message));
        }
    };


    return (
        <form onSubmit={handleSubmit} noValidate>
            {Object.entries(dropdownCategories).map(([key, dropdown]) => (
                <Dropdown
                    key={key}
                    label={dropdown.label}
                    name={key}
                    value={reviewFormData[key]}
                    onChange={handleChange}
                    options={dropdown.options}
                    error={formErrors[key]}
                    warning={formWarnings[key]}
                />
            ))}

            {checkboxCategories.map(category => (
                <CheckboxGroup
                    key={category.name}
                    category={category}
                    onChange={handleCheckboxChange(category.name)}
                    state={reviewFormData}
                    error={formErrors[category.name]}
                    warning={formWarnings[category.name]}
                />
            ))}

            {radioCategories.map(category => (
                <RadioButtonGroup
                    key={category.name}
                    name={category.name}
                    value={reviewFormData[category.name]}
                    onChange={handleChange}
                    options={category.options}
                    label={category.question}
                    error={formErrors[category.name]}
                />
            ))}

            <Textarea
                label="Additional Comments"
                name="additionalComments"
                value={reviewFormData.additionalComments}
                onChange={handleChange}
                error={formErrors.additionalComments}
                warning={formWarnings.additionalComments}
            />

            {/*{qrCodeDataUri && (*/}
            {/*    <div>*/}
            {/*        <img src={qrCodeDataUri} alt="QR Code" />*/}
            {/*        <button type="button" onClick={downloadQrCode}>Download QR Code</button>*/}
            {/*    </div>*/}
            {/*)}*/}

            <button type="submit">{isEditing ? 'Save Edits' : 'Submit Review'}</button>
            {isEditing && (
                <button type="button" onClick={handleDelete}>Delete Review</button>
            )}
        </form>
    );
};

export default Review;
