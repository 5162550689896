// src/config/sliderConfig.js
const sliderConfig = {
    accessibility: {
        labelLeft: "Accessible",
        labelRight: "Hard to Reach",
        min: -100,
        max: 100,
    },
    responseTime: {
        labelLeft: "Replies to messages or emails within 24 hours",
        labelRight: "It takes multiple emails to get a reply",
        min: -100,
        max: 100,
    },
    expectations: {
        labelLeft: "Aspirational Goals / High Expectations",
        labelRight: "Relaxed Goals/ Did Not Push Me",
        min: -100,
        max: 100,
    },
    judgmentLevel: {
        labelLeft: "Supportive Guidance / Judgment-free",
        labelRight: "Analytical Feedback / Critical",
        min: -100,
        max: 100,
    },
    openness: {
        labelLeft: "Flexible Approach / Open to New Ideas",
        labelRight: "Structured Approach / Fixed mindset",
        min: -100,
        max: 100,
    },
    micromanagementLevel: {
        labelLeft: "Direct Oversight / Micromanaging",
        labelRight: "Guided Autonomy / Encourages Independence / Gives Me Freedom to Explore",
        min: -100,
        max: 100,
    },
    meetingClarity: {
        labelLeft: "Open-Ended Discussions / Walked away from meetings, feeling more confused",
        labelRight: "Focused Discussions / Walked away from meetings, with greater clarity",
        min: -100,
        max: 100,
    },
    feedbackQuality: {
        labelLeft: "Reserved Feedback / Could Give More Feedback",
        labelRight: "Detailed Feedback / Offers Useful Feedback",
        min: -100,
        max: 100,
    },
    developmentOpportunities: {
        labelLeft: "Provides Development Opportunities",
        labelRight: "Lack of Professional Development",
        min: -100,
        max: 100,
    },
    fundingLevel: {
        labelLeft: "Well-funded",
        labelRight: "Limited funding",
        min: -100,
        max: 100,
    },
   teamOrientationLevel: {
        labelLeft: "Self-directed",
        labelRight: "Team-directed / Collaborative",
        min: -100,
        max: 100,
    },
    respectInclusivityLevel: {
        labelLeft: "Selective Respect / Respect for Some",
        labelRight: "Universal Respect / Respect for All",
        min: -100,
        max: 100,
    },
    workLifeBalanceLevel: {
        labelLeft: "Balance-Oriented Environment / Emphasis on work-life balance",
        labelRight: "Dedication-Oriented Environment / Overworked and burnt-out",
        min: -100,
        max: 100,
    },
    diversityInclusionLevel: {
        labelLeft: "Diverse and inclusive / Spectrum of Perspectives",
        labelRight: "Homogenous Culture / Consistent Perspectives",
        min: -100,
        max: 100,
    },
    socialCohesionLevel: {
        labelLeft: "Close-Knit Social Environment / My labmates are like a second family",
        labelRight: "Independent Professional Environment / I barely know my labmates",
        min: -100,
        max: 100,
    },
};

export default sliderConfig;
