// src/components/DepartmentWidget.jsx
import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { publicAxiosInstance } from '../../api/axios';
import { useDebounce } from '../../hooks/useDebounce';

const filter = createFilterOptions();

function DepartmentWidget({ initialDepartments, onDepartmentsUpdated }) {
    const [inputValue, setInputValue] = useState('');
    const [selectedDepartments, setSelectedDepartments] = useState(initialDepartments || []);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const debouncedInputValue = useDebounce(inputValue, 500);

    useEffect(() => {
        setSelectedDepartments(initialDepartments || []);
    }, [initialDepartments]);

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await publicAxiosInstance.get(`/api/v1/departments/?search=${encodeURIComponent(debouncedInputValue)}`);
                if (Array.isArray(response.data)) {
                    setDepartmentOptions(response.data.map(dept => dept.name));
                } else {
                    console.error("Expected array from API, received:", response.data);
                }
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };

        if (debouncedInputValue) {
            fetchDepartments(); // Fetch departments based on user input
        }
    }, [debouncedInputValue]);

    const handleChange = (event, newValue) => {
        setSelectedDepartments(newValue);
        onDepartmentsUpdated(newValue);
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    return (
        <Autocomplete
            multiple
            id="department-widget"
            options={departmentOptions}
            value={selectedDepartments}
            onChange={handleChange}
            onInputChange={handleInputChange}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '' && !options.includes(params.inputValue)) {
                    filtered.push(`Add "${params.inputValue}"`);
                }
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Departments"
                    placeholder="Search"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: null, // Removes the clear button
                    }}
                />
            )}
            getOptionLabel={(option) => option}
        />
    );
}

export default DepartmentWidget;
