// src/components/common/RadioButtonGroup.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styles from './SharedFormStyles.module.css';

const RadioOption = ({ name, option, value, onChange }) => {
    const isChecked = value === option.value;

    return (
        <div className="radio-option">
            <input
                type="radio"
                id={`${name}-${option.value}`}
                name={name}
                value={option.value}
                checked={isChecked}
                onChange={onChange}
            />
            <label htmlFor={`${name}-${option.value}`}>{option.label}</label>
        </div>
    );
};

RadioOption.propTypes = {
    name: PropTypes.string.isRequired,
    option: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
    }).isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

const RadioButtonGroup = ({ name, value, onChange, options, label, className, error }) => {
    let fieldClassName = `${className} ${error ? styles['form-field-error'] : ''}`.trim();

    return (
        <fieldset className={fieldClassName}>
            <legend>{label}</legend>
            {options.map(option => (
                <RadioOption
                    key={`${name}-${option.value}`}
                    name={name}
                    option={option}
                    value={value}
                    onChange={onChange}
                />
            ))}
            {error && <p id={`error-${name}`} className="error-message">{error}</p>}
        </fieldset>
    );
};

RadioButtonGroup.defaultProps = {
    className: "",
};

RadioButtonGroup.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
    })).isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    error: PropTypes.string
};

export default RadioButtonGroup;