// src/components/MessageDisplay.js
import React, { useContext } from 'react';
import { MessageContext } from '../../contexts/MessageContext';
import styles from './MessageDisplay.module.css';

const MessageDisplay = () => {
    const {messages, removeMessage} = useContext(MessageContext);

    if (messages.length === 0) return null; // Don't render if no messages

    console.log("Rendering messages:", messages);

    return (
        <div className={styles['message-container']}>
            {messages.map(message => (
                <div key={message.id} className={`${styles.message} ${styles[`${message.type}-msg`] || ''}`}>
                    {message.text} {/* Ensure to render message.text, not the message object */}
                    <button
                        onClick={() => removeMessage(message.id)}
                        className={styles['close-btn']}
                        aria-label="Close message">
                        X
                    </button>
                </div>
            ))}
        </div>
    );
};

export default MessageDisplay;
