// src/components/SchoolInfo.jsx
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import SearchableAdvisorTable from './SearchableAdvisorTable';
import { Typography, Box } from '@mui/material';

const SchoolInfo = ({ schoolName, advisors }) => {
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'ascending'});

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    }

    // Sort advisors based on sortConfig
    const sortedAdvisors = useMemo(() => {
        let sortableItems = [...advisors];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [advisors, sortConfig]);


    const isDataEmpty = !sortedAdvisors || sortedAdvisors.length === 0;

    return (
        <Box>
            <Typography variant="h4" component="h1" gutterBottom>
                {schoolName || 'Your School'}
            </Typography>
            {isDataEmpty ? (
                <Typography>No advisors found for your school.</Typography>
            ) : (
                <SearchableAdvisorTable
                    advisors={sortedAdvisors}
                    requestSort={requestSort}
                />
            )}
        </Box>
    );
};

SchoolInfo.propTypes = {
    schoolName: PropTypes.string.isRequired,
    advisors: PropTypes.array.isRequired,
};

export default SchoolInfo;
