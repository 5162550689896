// src/components/ProtectedRoute.jsx
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import LoadingComponent from './widgets/LoadingComponent';

const ProtectedRoute = ({ children }) => {
    console.log("ProtectedRoute - Component Render Start");//TODO: REMOVE in Production
    const {authState, initialAuthCheckDone } = useContext(AuthContext);
    console.log("ProtectedRoute - Auth State:", authState.isAuthorized);//TODO: REMOVE in Production
    console.log("ProtectedRoute - Initial Auth Check Done:", initialAuthCheckDone);//TODO: REMOVE in Production

    // Wait for the initial django_authentication check to complete
    if (!initialAuthCheckDone) {
        console.log("ProtectedRoute - Waiting for initial auth check"); //TODO: REMOVE in Production
        return <LoadingComponent />; // Show loading while checking initial auth status
    }

    console.log("ProtectedRoute - authState.isAuthorized:", authState.isAuthorized);  //TODO: REMOVE in Production

    // If the user is not authorized, redirect to the home page
    if (!authState.isAuthorized) {
        console.log("ProtectedRoute - Redirecting to home (user not authorized)");  //TODO: REMOVE in Production
        return <Navigate to="/" />;
    }

    console.log("ProtectedRoute - Rendering children (user authorized)");  //TODO: REMOVE in Production
    // If the user is authorized, render the child components
    return children;
};

export default ProtectedRoute;