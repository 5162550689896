// src/utils/errorHandler.js
const handleErrors = (error) => {
    console.error('Error:', error); // TODO remove in production

    let errorMessage = 'An unexpected error occurred. Please try again later.';

    if (error.response) {
        // Server responded with a status code outside the 2xx range
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);

        errorMessage = error.response.data.detail || error.response.data.error || errorMessage;

        // Log the full response for detailed analysis
        console.error('Full error response:', error.response); // TODO remove in production

        if (error.response.status === 500) {
            console.error('Server error details:', error.response.data);
            errorMessage = 'A server error occurred. Please contact support if the problem persists.';
        }
    } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        errorMessage = 'No response from the server. Please check your network connection.';
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
        errorMessage = `Request setup error: ${error.message}`;
    }

    // Log the request configuration for further debugging
    if (error.config) {
        console.error('Error request config:', error.config);
    }

    return errorMessage;
};

export default handleErrors;
