// ThemeToggle.jsx
import React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useTheme } from '../../contexts/ThemeContext';


 const ColorIconButton = styled(IconButton)(({ theme }) => ({
   color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.54)' : 'rgba(0, 0, 0, 0.54)',
 }));

const ThemeToggle = () => {
  const { mode, toggleTheme } = useTheme();


  const getIcon = () => {
    // Use `mode` to determine which icon to display
    return mode === 'light' ? <LightModeIcon /> : <DarkModeIcon />;
  };

  // const getIcon = () => {    switch (theme) {
  //     case 'light':
  //       return <LightModeIcon />;
  //     default:
  //       return <DarkModeIcon />;
  //   }
  // };

  return (
      <ColorIconButton id="theme-toggle" onClick={toggleTheme} aria-label="Toggle Theme">
        {getIcon()}
      </ColorIconButton>
  );
};

export default ThemeToggle;
