// src/components/review/AdvisorDescriptionSection.jsx
import React from 'react';
import TwoWaySlider from '../common/TwoWaySlider';
import sliderConfig from '../../config/sliderConfig';

const AdvisorDescriptionSection = ({ formData, handleChange }) => {
        const displayedSliders = [
                "expectations",
                "judgmentLevel",
                "openness",
                "micromanagementLevel",
                "meetingClarity",
                "feedbackQuality",
                "developmentOpportunities",
                "fundingLevel",
        ];

        return (
            <section>
                    {displayedSliders.map(key => {
                            const config = sliderConfig[key];
                            if (!config) {
                                    console.warn(`Configuration for "${key}" not found in sliderConfig.`);
                                    return null;
                            }

                            // Accessing nested slider values from formData.attributes.sliders
                            const sliderValue = formData.attributes.sliders[key] || 0;


                            return (
                                <TwoWaySlider
                                    key={key}
                                    labelLeft={config.labelLeft}
                                    labelRight={config.labelRight}
                                    min={config.min}
                                    max={config.max}
                                    name={key}
                                    value={sliderValue}
                                    // Update onChange to pass the entire event with a nested name to handle nested state update
                                    onChange={(e) => handleChange({
                                            target: {
                                                    name: `attributes.sliders.${key}`,
                                                    value: e.target.value
                                            }
                                    })}
                                />
                            );
                    })}
            </section>
        );
};

export default AdvisorDescriptionSection;
