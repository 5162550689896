// colors.js

// Light theme color definitions
export const lightColors = {
  primary: '#f8f9fa', // Main color for primary elements, simplified from primaryMain
  primaryContrast: '#fff', // Foreground (text) color on primary elements, adjusted from primaryFg
  secondary: '#b6a37d', // Main color for secondary elements, simplified from secondaryMain
  secondaryContrast: '#202124', // Foreground (text) color on secondary elements, adjusted from secondaryFg
  accent: '#ffff99', // Accent color used for highlighting or emphasis, simplified from accentMain

  background: '#fff', // Background color of the body or main content area, simplified from bodyBg
  text: '#333', // Default text color for body content, simplified from bodyFg
  textMuted: '#666', // Subdued text color for less emphasis, adjusted from bodyQuietColor
  textBold: '#000', // Bold or bright text color for more emphasis, adjusted from bodyLoudColor

  navBackground: '#f8f9fa', // Background color for the navigation bar, adjusted from navBarBg
  navText: '#202124', // Foreground (text) color for the navigation bar, adjusted from navBarFg

  footer: '#f8f9fa', // Background color for the footer, adjusted from footerBg
  footerContrast: '#202124', // Foreground (text) color for the footer, adjusted from footerFg

  widget: '#fff',
  widgetContrast: '#333', // Foreground color for widgets or similar components, simplified from widgetFg

  link: '#0645ad', // Default link color, adjusted from linkColor
  linkHover: '#036', // Color for links on hover, retained as is
  linkActive: '#5b80b2', // Color for selected or active links, adjusted from linkSelectedFg

  border: '#000', // Default color for borders, simplified from borderColor

  error: '#ba2121', // Text color for error messages or elements, simplified from errorFg
  success: '#dfd', // Background color for success messages, adjusted from messageSuccessBg
  warning: '#ffc', // Background color for warning messages, adjusted from messageWarningBg
  errorBackground: '#ffefef', // Background color for error messages, adjusted from messageErrorBg

  darkened: '#f8f8f8', // Slightly darker background color, simplified from darkenedBg
  selected: '#ff0000', // Background color for selected items, simplified from selectedBg
  selectedRow: '#ffc', // Background color for selected table rows or list items, retained as is

  button: '#f8f9fa', // Default background color for buttons, adjusted from buttonBg
  buttonHover: '#609ab6', // Background color for buttons on hover, retained as is
  buttonDefault: '#b6a37d', // Background color for default or secondary buttons, adjusted from defaultButtonBg
  buttonDefaultHover: '#205067', // Hover background color for default or secondary buttons, adjusted from defaultButtonHoverBg

  closeButton: '#747474', // Background color for close buttons, adjusted from closeButtonBg
  closeButtonHover: '#333', // Hover background color for close buttons, adjusted from closeButtonHoverBg

  deleteButton: '#ba2121', // Background color for delete or negative action buttons, adjusted from deleteButtonBg
  deleteButtonHover: '#a41515', // Hover background color for delete or negative action buttons, adjusted from deleteButtonHoverBg

  objectTools: '#747474', // Background color for tool icons or buttons, adjusted from objectToolsBg
  objectToolsHover: '#333', // Hover background color for tool icons or buttons, adjusted from objectToolsHoverBg
};

// Dark theme color definitions
export const darkColors = {
  primary: '#575757',
  primaryContrast: '#eee',
  secondary: '#686868',
  secondaryContrast: '#ddd',
  accent: '#ffff99',

  background: '#3d3d3d',
  text: '#ccc',
  textMuted: '#ccc',
  textBold: '#fff',

  navBackground: '#575757',
  navText: '#f7f7f7',

  footer: '#575757',
  footerContrast: '#f7f7f7',

  widget: '#fff',
  widgetContrast: '#ccc',

  link: '#81d4fa',
  linkHover: '#4ac1f7',
  linkActive: '#6f94c6',

  border: '#f7f7f7',

  error: '#e35f5f',
  success: '#006b1b',
  warning: '#583305',
  errorBackground: '#570808',

  darkened: '#212121',
  selected: '#1b1b1b',
  selectedRow: '#00363a',

  button: '#575757',
  buttonHover: '#666666',
  buttonDefault: '#575757',
  buttonDefaultHover: '#666666',

  closeButton: '#333333',
  closeButtonHover: '#666666',

  deleteButton: '#e35f5f',
  deleteButtonHover: '#ba2121',

  objectTools: '#333333',
  objectToolsHover: '#666666',
};
