// src/contexts/MessageContext.js
import React, { useState, createContext, useRef, useEffect, useCallback } from 'react';
import { eventEmitter } from '../utils/eventEmitter';

export const MessageContext = createContext(null);

export const MessageProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const messageIdCounter = useRef(0); // Counter to generate unique message IDs

    const addMessage = useCallback((message) => {
        console.log("Adding message:", message, "Type of text:", typeof message.text);
        const id = messageIdCounter.current++;
        const newMessage = { id, ...message };
        setMessages(prevMessages => [...prevMessages, newMessage]);
    }, []);

    const removeMessage = useCallback((messageId) => {
        setMessages(prevMessages => prevMessages.filter(msg => msg.id !== messageId));
    }, []);

    const clearMessages = useCallback(() => {
        setMessages([]);
    }, []);



    // Helper functions for adding message types

    const addErrorMessage = useCallback((text) => {
        addMessage({ text, type: 'error' });
    }, [addMessage]);

    const addInfoMessage = useCallback((text) => {
        addMessage({ text, type: 'info' });
    }, [addMessage]);

    const addSuccessMessage = useCallback((text) => {
        addMessage({ text, type: 'success' });
    }, [addMessage]);

    const addWarningMessage = useCallback((text) => {
        addMessage({ text, type: 'warning' });
    }, [addMessage]);



    useEffect(() => {
        const handleApiError = ({ text }) => {
            console.log('apiError event received:', text);
            addErrorMessage(text);
        };
        eventEmitter.on('apiError', handleApiError);

        const handleError = ({ text }) => {
            console.log('Error event received:', text);
            addErrorMessage(text);
        };
        eventEmitter.on('errorMessage', handleError);

        const handleSuccessMessage = ({ text }) => {
            console.log('successMessage event received:', text);
            addSuccessMessage(text);
        };
        eventEmitter.on('successMessage', handleSuccessMessage);

        const handleWarningMessage = ({ text }) => {
            console.log('warningMessage event received:', text);
            addWarningMessage(text);
        };
        eventEmitter.on('warningMessage', handleWarningMessage);

        return () => {
            eventEmitter.off('apiError', handleApiError);
            eventEmitter.off('errorMessage', handleError);
            eventEmitter.off('successMessage', handleSuccessMessage);
            eventEmitter.off('warningMessage', handleWarningMessage);
        };
    }, [addErrorMessage, addSuccessMessage, addWarningMessage]);


    return (
        <MessageContext.Provider value={{
            messages,
            addMessage,
            addErrorMessage,
            addInfoMessage,
            addSuccessMessage,
            clearMessages,
            removeMessage
        }}>
            {children}
        </MessageContext.Provider>
    );
};

// TODO: Timeout for messages: Depending on your application's needs,
//  you might want to add an optional timeout for messages, after which they automatically disappear.
//  This could be particularly useful for transient messages like success notifications or alerts.

//  TODO: Message Types: Ensure the message types (e.g., 'info', 'error', 'success') are clearly documented or standardized.
//   You could even consider using constants or an enumeration to define these types.

/*
// Example of adding an error message
addMessage({ type: 'error', text: 'An error occurred.' });

// Example of adding a success message
addMessage({ type: 'success', text: 'Operation completed successfully.' });

----

// swap to default functions in production
  export const MessageContext = createContext({
  messages: [],
  addMessage: () => {},
  clearMessages: () => {},
  removeMessage: () => {}
});
*/