// src/pages/AdvisorProfile.jsx
import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import { useParams} from 'react-router-dom';
import { publicAxiosInstance, privateAxiosInstance } from '../api/axios';
import { AuthContext } from '../contexts/AuthContext';
import styles from './AdvisorProfile.module.css';
// import ReviewSummary from "../components/ReviewSummary";
import ReviewForm from '../components/ReviewForm';
import DepartmentWidget from '../components/widgets/DepartmentWidget';
import LoadingComponent from "../components/widgets/LoadingComponent";
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import handleErrors from '../utils/errorHandler';


// TODO: The component is quite large and handles multiple responsibilities, including fetching data,
//  rendering UI elements, and managing edit and review forms. Consider breaking down the component into smaller,
//  more focused components to improve readability and maintainability.
//  For example, the editing functionality for advisor details could be extracted into its own component,
//  which would simplify the AdvisorProfile component and make the codebase more modular.
function AdvisorProfile() {
    console.log('Initializing AdvisorProfile component');
    // State and Context
    const {advisorId} = useParams();
    const {authState} = useContext(AuthContext); // Use AuthContext
    const [advisor, setAdvisor] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showReviewForm, setShowReviewForm] = useState(false);
    const [hasUserReview, setHasUserReview] = useState(false); // Boolean to track if user has a review
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedResearchArea, setEditedResearchArea] = useState('');


    // Additional state to store original department data before editing
    const [originalDepartments, setOriginalDepartments] = useState([]);

    // State to keep track of selected departments
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    // Determine if the user can review this advisor
    const canReview = authState.isAuthorized && authState.school.id === advisor?.school;
    //console.log("Can review:", canReview);   //TODO: remove in production

    const reviewButtonText = canReview ?
        (hasUserReview ? "Edit My Review" : "Leave a Review") :
        (authState.isAuthorized ? "You cannot review advisors from other schools" : "Sign In to Leave A Review");


    // Utility Functions
    const displayDepartments = (departments) => {
        if (departments && departments.length > 0) {
            return departments.map(dept => dept.name).join(', ');
        }
        return 'Unknown';
    };

    const concatenateDepartmentNames = (departments) => {
        return departments && Array.isArray(departments)
            ? departments.map(dept => dept.name).join(', ')
            : '';
    };


    // Event Handlers
    // Toggle edit mode and initialize input fields
    const handleEditAdvisor = () => {
        setIsEditMode(!isEditMode);
        // setEditedDepartment(concatenateDepartmentNames(advisor.departments));
        setEditedResearchArea(advisor.research_area || '');
    };

    // New handler for cancel button
    const handleCancelAdvisorEdit = () => {
        setIsEditMode(false);
        setSelectedDepartments(originalDepartments); // Reset to original departments
        setEditedResearchArea(advisor.research_area || ''); // Reset to original research area
    };

    const handleSaveAdvisorEdit = async () => {
        const updatedFields = {};
        // Convert arrays to strings to compare
        const selectedDepartmentsString = selectedDepartments.join(', ');
        const advisorDepartmentsString = concatenateDepartmentNames(advisor.departments);
        if (selectedDepartmentsString !== advisorDepartmentsString) {
            updatedFields.department_names = selectedDepartmentsString.split(', ');
            // ... rest of your update logic
        }

        if (advisor.research_area !== editedResearchArea) {
            updatedFields.research_area = editedResearchArea;
        }

        if (Object.keys(updatedFields).length > 0) {
            try {
                console.log('Updated department names:', updatedFields.department_names);   //TODO: remove in production
                const response = await privateAxiosInstance.patch(`/api/v1/advisors/${advisorId}/`, updatedFields);
                // Update local state with the response data
                setAdvisor({...advisor, ...response.data});
                setIsEditMode(false);
            } catch (error) {
                console.error("Error updating advisor details:", error);
            }
        } else {
            // No fields changed
            setIsEditMode(false);
        }
    };

    const handleReviewSubmit = () => {
        // Logic to handle after review submission
        setShowReviewForm(false);
        //TODO: refresh page?
    };

    const handleResearchAreaChange = (event) => {
        setEditedResearchArea(event.target.value);
    };

        // Handler to update state when departments change
    const handleDepartmentsUpdated = (newDepartments) => {
        setSelectedDepartments(newDepartments);
    };

    const effectCounter1 = useRef(0);
    const effectCounter2 = useRef(0);

    // Synchronizes department data with advisor changes. On advisor update, it extracts
    // department names and sets them as both the initial state (originalDepartments) for potential
    // reversion on edit cancellation, and the current state (selectedDepartments) for display and editing.
    useEffect(() => {
        effectCounter1.current++;
        console.log(`Effect 1 has run ${effectCounter1.current} times`);
        // Store the original department data when advisor data is fetched
        if (advisor && advisor.departments) {
            const deptNames = advisor.departments.map(dept => dept.name);
            setOriginalDepartments(deptNames);  // Save original department names for reset on edit cancel
            setSelectedDepartments(deptNames);  // Set current department names for display and editing
        }
    }, [advisor]);

    // Using useCallback to memoize the fetchData function
    const fetchData = useCallback(async () => {
        console.log('fetchData function is being called');//TODO: remove in production
        setIsLoading(true);
        console.log(`START LOADING`);//TODO: remove in production
        try {
            console.log(`Attempting to fetch advisor data for ID: ${advisorId}`);
            // Fetch Advisor Data including reviews
            const advisorResponse = await publicAxiosInstance.get(`/api/v1/advisors/${advisorId}/`);
            console.log("Received advisor data:", advisorResponse.data);  //TODO: remove in production
            // Update the advisor state with fetched data
            setAdvisor(advisorResponse.data);
            // Update the selected departments based on fetched advisor data
            setSelectedDepartments(advisorResponse.data.departments.map(dept => dept.name));

            if (authState.isAuthorized) {
                 //TODO: We could probably optimize this, since all reviews are returned in the previous API fetch
                const userReviewResponse = await privateAxiosInstance.get(`/api/v1/reviews/user-reviews/?advisor_id=${advisorId}`);
                setHasUserReview(userReviewResponse.data.length > 0);
            }


        } catch (error) {
            console.log("Error fetching advisor data", error);//TODO: remove in production
            console.error("Error fetching advisor data:", handleErrors(error));
        } finally {
            setIsLoading(false);
            console.log(`DONE LOADING`);//TODO: remove in production
        }
    }, [advisorId, authState.isAuthorized]);

    // Effect for fetching advisor data and checking user reviews
    useEffect(() => {
        effectCounter2.current++;//TODO: remove in production
        console.log(`Effect 2 has run ${effectCounter2.current} times`);//TODO: remove in production
        fetchData();
    }, [fetchData]);


    // JSX layout and rendering
    if (isLoading) return <LoadingComponent/>;
    if (!advisor) return <div>No advisor data</div>;

    console.log("Review button text:", reviewButtonText);  //TODO: remove in production

    return (
        <Box className={`page-container ${styles['advisor-container']}`}>
            <Box className={styles['title-bar']}>
                <Typography variant="h4" gutterBottom>
                    {advisor.name}
                </Typography>
                {canReview && (
                    <Stack spacing={2} direction="row">
                        {isEditMode ? (
                            <>
                                <Button variant="contained" onClick={handleSaveAdvisorEdit} className={styles['save-btn']}>Save</Button>
                                <Button variant="outlined" onClick={handleCancelAdvisorEdit} className={styles['cancel-btn']}>Cancel</Button>
                            </>
                        ) : (
                            <Button variant="text" onClick={handleEditAdvisor} className={styles['edit-btn']}>Edit Advisor</Button>
                        )}
                    </Stack>
                )}
            </Box>

            <Typography variant="h5" gutterBottom component={Link} to={`/school/${advisor.school}`} className={styles['school-link']}>
                    <span className={styles['school-name']}>{advisor.school_name}</span>
            </Typography>
            <Typography variant="body1" gutterBottom style={{ paddingBottom: '2rem' }} >
                {isEditMode ? (
                    <DepartmentWidget
                        initialDepartments={selectedDepartments}
                        onDepartmentsUpdated={handleDepartmentsUpdated}
                    />
                ) : (
                    <>
                        <b>Department: </b>
                        {displayDepartments(advisor.departments)}
                    </>
                )}
                <br />
                <b>Research Area: </b>
                {isEditMode ? (
                    <input
                        type="text"
                        value={editedResearchArea}
                        onChange={handleResearchAreaChange}
                    />
                ) : advisor.research_area || "Unknown"}
            </Typography>


            {showReviewForm ? (
                <ReviewForm advisorId={advisorId} onReviewSubmit={handleReviewSubmit}/>
            ) : (
                <>
                    {canReview ? (
                       <Button variant="contained" onClick={() => setShowReviewForm(true)}>{reviewButtonText}</Button>
                    ) : (
                        <span>{reviewButtonText}</span>
                        //<button onClick={handleSignIn}>{reviewButtonText}</button> // Implement handleSignIn to redirect to sign-in page
                    )}
                    {/*<ReviewSummary reviewData={advisor.reviews || []} />*/}
                </>
            )}
        </Box>
    );
}

export default AdvisorProfile;
