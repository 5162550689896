// src/config/formOptions.js
// AggregateReviews is dependent on this script. If you make changes, make sure to also update AggregateReviews.jsx


// Enums for category names for better maintainability and consistency
const CheckboxCategoryEnum = {
    ADVISOR_EXPERIENCE: 'advisorExperience',
    //ADVISOR_STRENGTHS: 'advisorStrengths',
    //ADVISOR_WEAKNESSES: 'advisorWeaknesses',
    LAB_CULTURE: 'labCulture',
    // Additional checkbox categories can be added here
};

const RadioCategoryEnum = {
    GRADUATE_ON_TIME: 'graduateOnTime',
    RECOMMEND_ADVISOR: 'recommendAdvisor',
    // Additional radio button categories can be added here
};

const DropdownCategoryEnum = {
    MEETING_FREQUENCY: 'meetingFrequency',
    // Additional dropdown categories can be added here
};

const SliderCategoryEnum = {
    FEEDBACK_QUALITY: 'feedbackQuality',
    // Additional slider categories can be added here
};



// Define checkbox categories with options
const checkboxCategories = [
    {
        name: CheckboxCategoryEnum.ADVISOR_EXPERIENCE,
        question: 'How would you describe your advisor and your experience with them?',
        options: [
            'Kind', 'Mentorship-focused',
            // ... more options ...
        ],
    },
    // {
    //     name: CheckboxCategoryEnum.ADVISOR_STRENGTHS,
    //     question: 'What are your advisor’s strengths?',
    //     options: [
    //         'Kind', 'Mentorship-focused', 'Judgment-free', 'Offers useful critiques',
    //         'Brings up opportunities', 'Accessible', 'Replies to messages/emails',
    //         'Well-funded', 'Well-connected', 'Expertise in the field',
    //         'Teaching skills', 'Encourages independence',
    //         // ... more options ...
    //     ],
    // },
    // {
    //     name: CheckboxCategoryEnum.ADVISOR_WEAKNESSES,
    //     question: 'What are your advisor’s weaknesses?',
    //     options: [
    //         'Hard to reach / issues with accessibility', 'Overextended',
    //         // ... more options ...
    //     ],
    // },
    {
        name: CheckboxCategoryEnum.LAB_CULTURE,
        question: 'What is the lab culture like?',
        options: [
            'Competitive', 'Collaborative', 'Respect for all', 'Emphasis on work-life balance',
            'Overworked and burnt out', 'Diverse and inclusive', 'Exclusivity',
            // ... more options ...
        ],
    },
    // Other checkbox categories...
];

// Define radio button categories with options
const radioCategories = [
    {
        name: RadioCategoryEnum.GRADUATE_ON_TIME,
        question: 'Do people graduate on time in your lab?',
        options: [{label: 'Yes', value: true}, {label: 'No', value: false}],
    },
    {
        name: RadioCategoryEnum.RECOMMEND_ADVISOR,
        question: 'Would you recommend this advisor?',
        options: [{label: 'Yes', value: true}, {label: 'No', value: false}],
    },
    // Other radio button categories...
];

// Define dropdown categories with options
const dropdownCategories = {
    [DropdownCategoryEnum.MEETING_FREQUENCY]: {
        label: 'Meeting Frequency',
        options: [
            {label: 'More than weekly / open-door policy', value: 'MTW'},
            {label: 'Weekly', value: 'WKY'},
            {label: 'Biweekly', value: 'BWK'},
            {label: 'Monthly', value: 'MON'},
            {label: 'Less than monthly', value: 'LTM'},
            {label: 'I don’t directly work with my advisor', value: 'ND'},
            // ... more options ...
        ]
    },
    // Other dropdown categories...
};


// Define slider categories with properties
const sliderCategories = [
    {
        name: SliderCategoryEnum.FEEDBACK_QUALITY,
        label: 'Feedback Quality',
        min: -100,
        max: 100,
        step: 1,
        defaultValue: 0,
        leftLabel: 'Offers Useful Feedback',
        rightLabel: 'Could Give More Feedback'
    },
    // Other slider categories...
];


// Export enums for consistency across the application
export {
    CheckboxCategoryEnum,
    RadioCategoryEnum,
    DropdownCategoryEnum,
    SliderCategoryEnum,
    checkboxCategories,
    radioCategories,
    dropdownCategories,
    sliderCategories
};
