// src/hooks/useGoogleAuth.js
import { useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { publicAxiosInstance } from '../api/axios';

const useGoogleAuth = () => {
    const {updateAuthState} = useContext(AuthContext);
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    // Callback function to handle authentication with the backend
    const authenticateWithBackend = useCallback((idToken) => {
        console.log("Sending ID token to backend: ", idToken);
        publicAxiosInstance.post('/api/v1/authenticate/', { token: idToken })
            .then(response => {
                console.log('Authentication successful:', response.data);
                const {status, school} = response.data;

                if (status === 'success') {
                    console.log("Setting auth state in OAuthLoginButton");
                    sessionStorage.setItem('idToken', idToken);
                    console.log("Storing idToken in sessionStorage");
                   updateAuthState({
                        isAuthorized: true,
                        school: school,
                        loading: false,
                        error: null
                    });
                    console.log("AuthState update called in OAuthLoginButton");
                    navigate('/dashboard');
                } else {
                    // Handle unsuccessful django_authentication
                    setError('Authentication failed. Please try again.');
                }
            })
            .catch(error => {
                console.error('Authentication failed:', error);
                setError('Authentication failed. Please try again.');
            });
    }, [updateAuthState, navigate]);

    // Callback function for Google Sign-In response
    const handleCredentialResponse = useCallback((response) => {
        console.log("Encoded Google JWT ID token: " + response.credential);
        setError(null);
        authenticateWithBackend(response.credential);
    }, [authenticateWithBackend]);

    // Function to trigger the Google Sign-In prompt
    const signIn = useCallback(() => {
        if (!window.google || !window.google.accounts) {
            setError("Google API not loaded yet.");
            return;
        }

        window.google.accounts.id.prompt((notification) => {
            if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                setError("Google Sign-In was skipped or not displayed.");
            }
        });
    }, []);

    return { signIn, error, handleCredentialResponse };
};

export default useGoogleAuth;