// src/components/DashboardButton.jsx
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import styles from "./DashboardButton.module.css";

const DashboardButton = () => {
    const navigate = useNavigate();
    const { authState } = useContext(AuthContext);
    const school = authState?.school;

    const handleDashboardClick = () => {
        navigate('/dashboard');
    };

    return (
        <button onClick={handleDashboardClick} className={styles.dashboardButton}>
            {school ? `${school.name}` : 'Your School'}
        </button>
    );
};

export default DashboardButton;