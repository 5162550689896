/**
 * src/django_api/axios.js
 *
 * Central HTTP request handlers for the React application using Axios.
 * Configures two Axios instances to manage API calls:
 * - publicAxiosInstance: For public (unauthenticated) requests.
 * - privateAxiosInstance: For private (authenticated) requests, with session handling.
 *
 * Features:
 * - Global base URL setup for consistent API requests across both instances.
 * - Default timeout and headers configuration for all requests.
 * - Separate handling of django_authentication tokens in privateAxiosInstance.
 * - Unified error handling via response interceptors in both instances:
 *   - Catches errors globally for every request made using these instances.
 *   - Utilizes eventEmitter to broadcast error messages to the application.
 *   - Handles 401 errors (Unauthorized) and other network errors, with specific handling for token expiration in privateAxiosInstance.
 *
 * This setup streamlines HTTP requests across the app, ensures proper handling of authenticated requests,
 * and centralizes error handling for API interactions.
 */
// src/django_api/axios.js
import axios from 'axios';
import { backendURL } from '../config';
import { jwtDecode } from 'jwt-decode';
import { eventEmitter } from '../utils/eventEmitter';
import authService from './authService';

// Standalone function to check token expiration
const isTokenExpired = (token) => {
    // console.log("Checking token in isTokenExpired:", token);
    if (!token) return true;
    try {
        const decodedToken = jwtDecode(token);
        // console.log("Decoded token:", decodedToken);
        const currentTime = Date.now() / 1000;
        const isExpired = decodedToken.exp < currentTime;
        // console.log(`Token expiration check: Token expired = ${isExpired}, TokenExp (${decodedToken.exp}) < currentTime(${currentTime})`);
        return isExpired;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
};


// Common base configuration
const baseConfig = {
  baseURL: backendURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
};


// Initialize Axios instances with the base configuration
const publicAxiosInstance = axios.create({ ...baseConfig });
const privateAxiosInstance = axios.create({ ...baseConfig });


// Error Handler
const handleError = error => {
  let errorMessage = error.response?.data?.detail || error.message || 'An unknown network error occurred';
  eventEmitter.emitError('apiError', new Error(errorMessage));
  return Promise.reject(error);
};


// Add Instance interceptors
publicAxiosInstance.interceptors.response.use(response => response, handleError);
privateAxiosInstance.interceptors.response.use(response => response, handleError);


// Token Management for Private Instance
privateAxiosInstance.interceptors.request.use(config => {
  const idToken = authService.getIdToken();
  // console.log("Retrieved token from sessionStorage:", idToken);

  if (idToken && !isTokenExpired(idToken)) {
    config.headers.Authorization = `Bearer ${idToken}`;
  } else {
      console.log("Token expired or not found in sessionStorage");
      // TODO: Implement more graceful fallback for token expiry, modal or toast notification component
      window.location.href = '/';
      return Promise.reject({message: "Token expired, please reauthenticate."});
  }
  // console.log("Axios request config with Authorization header:", config);
  return config;
}, error => Promise.reject(error));


export { publicAxiosInstance, privateAxiosInstance };














/* // JWT Token Interceptor
axiosInstance.interceptors.request.use((config) => {
    // Exclude Authorization header for specific endpoints
    const excludedEndpoints = ['/auth/django_api/get-jwt-token/'];
    if (!excludedEndpoints.includes(config.url)) {
        // Retrieve JWT token from session storage
        const jwtToken = sessionStorage.getItem('jwtToken');
        if (jwtToken) {
            config.headers['Authorization'] = `Bearer ${jwtToken}`;
        }
    }
    // CSRF token setup can be removed if you're not using CSRF tokens with JWT
    // If you still want CSRF protection for certain endpoints, you can retain this code
    const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='));
    if (token) {
        config.headers['X-CSRFToken'] = token.split('=')[1];
    }

    return config;
}, error => {
    return Promise.reject(error);
});
 */