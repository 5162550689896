// src/pages/Dashboard.jsx
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { privateAxiosInstance } from '../api/axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
// import styles from './Dashboard.module.css';
import LoadingComponent from '../components/widgets/LoadingComponent';
import SchoolInfo from '../components/SchoolInfo';

const Dashboard = () => {
  const {authState} = useContext(AuthContext);
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleErrorResponse = useCallback((err) => {
    console.error('Error fetching data:', err);
    setError(err.response?.data?.detail || err.message || 'An error occurred while fetching data.');
    setIsLoading(false);  // Ensure loading is false on error
    if (err.response && [401, 403, 404].includes(err.response.status)) {
      console.log(`Redirecting to home due to error status: ${err.response.status}`);
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    console.log('Dashboard useEffect'); //todo remove
    const abortController = new AbortController();
    const fetchDashboardData = async () => {
      setIsLoading(true);
      try {
        const response = await privateAxiosInstance.get('/api/v1/dashboard/', {
          signal: abortController.signal
        });
        console.log('Dashboard data:', response.data);
        setDashboardData(response.data);
      } catch (err) {
        if (!abortController.signal.aborted) {
          handleErrorResponse(err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (authState.isAuthorized) {
      console.log(`calling fetchDashboardData`); //todo remove
      fetchDashboardData();
    } else {
      console.log(`Redirecting to home since user state is not authorized`); //todo remove
      navigate('/'); // Redirect if not authorized
    }

    return () => {
      abortController.abort(); // Cleanup: cancel the request
    };
  }, [authState.isAuthorized, navigate, handleErrorResponse]);

  if (isLoading) {
    return <LoadingComponent/>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!dashboardData) {
    return <div>Missing dashboard data.</div>;
  }

  return (
      <div className='page-container'>
        <SchoolInfo
            schoolName={authState.school?.name}
            advisors={dashboardData.advisors || []}
        />
      </div>
  );
};

export default Dashboard;
