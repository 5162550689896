// src/components/common/Dropdown.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styles from './SharedFormStyles.module.css';

const Dropdown = ({
    label,
    name,
    value,
    onChange,
    options = [],
    error,
    warning,
    ariaDescribedby = `error${name}`,
    placeholder = "Select an option",
    className = ''
}) => {
    if (options.length === 0) return null;  // Optionally render nothing if no options are provided

    let fieldClassName = `${className} ${error ? styles['form-field-error'] : (warning ? styles['form-field-warning'] : '')}`;

    return (
        <div className={fieldClassName}>
            <label htmlFor={name}>{label}</label>
            <select
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                aria-describedby={ariaDescribedby}
            >
                <option value="">{placeholder}</option>
                {options.map(opt => (
                    <option key={opt.value} value={opt.value}>
                        {opt.label}
                    </option>
                ))}
            </select>
            {error && <p className={styles['error-message']}>{error}</p>}
            {warning && <p className={styles['warning-message']}>{warning}</p>}
        </div>
    );
};

Dropdown.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
        })
    ),
    error: PropTypes.string,
    warning: PropTypes.string,
    ariaDescribedby: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string
};

export default Dropdown;

