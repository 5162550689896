// src/utils/caseConverter.js

// Helper functions
const toSnakeCase = (str) => str.replace(/([A-Z])/g, '_$1').toLowerCase();

const toCamelCase = (str) => str.replace(/(_\w)/g, (match) => match[1].toUpperCase());


export const convertToSnakeCase = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(convertToSnakeCase);
    }

    return Object.keys(obj).reduce((acc, key) => {
        const newKey = toSnakeCase(key);
        const value = obj[key];
        acc[newKey] = convertToSnakeCase(value);
        return acc;
    }, {});
};

export const convertToCamelCase = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(convertToCamelCase);
    }

    return Object.keys(obj).reduce((acc, key) => {
        const newKey = toCamelCase(key);
        const value = obj[key];
        acc[newKey] = convertToCamelCase(value);
        return acc;
    }, {});
};