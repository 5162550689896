// src/components/common/Textarea.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styles from './SharedFormStyles.module.css';

const Textarea = ({
    label, name, value, onChange, error, warning, ariaDescribedby = `error${name}`,
    rows, cols, resizable = true, placeholder, className
}) => {
    const textareaStyle = resizable ? {} : { resize: 'none' };

    let fieldClassName = `${className} ${error ? styles['form-field-error'] : (warning ? styles['form-field-warning'] : '')}`.trim();

    return (
        <div className={fieldClassName}>
            <label htmlFor={name}>{label}</label>
            <textarea
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                aria-describedby={ariaDescribedby}
                rows={rows}
                cols={cols}
                style={textareaStyle}
                placeholder={placeholder}
            />
            {error && <p className={styles['error-message']}>{error}</p>}
            {warning && <p className={styles['warning-message']}>{warning}</p>}
        </div>
    );
};

Textarea.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
    ariaDescribedby: PropTypes.string,
    rows: PropTypes.number,
    cols: PropTypes.number,
    resizable: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string
};

export default Textarea;
