// src/components/OAuthLoginButton.jsx
import React, { useContext } from 'react';
import styles from "./OAuthLogOutButton.module.css";
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const OAuthLogoutButton = () => {
    const navigate = useNavigate();
    const {logoutUser, authState } = useContext(AuthContext);

    const handleLogout = async () => {
        await logoutUser();
        if (!authState.isAuthorized) {
            navigate('/'); // Redirect on successful logout
        } else {
            // Handle failed logout if necessary
            // This could include displaying an error message
        }
    };

    return (
        <div className={styles['button-wrapper']}>
            <button onClick={handleLogout} className={styles.button}>
                <span className="material-symbols-outlined">&#xe9ba;</span>
            </button>
        </div>

    );
};

export default OAuthLogoutButton;
