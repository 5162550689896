// src/App.jsx
import { Routes, Route, Navigate } from 'react-router-dom';
// Components
import NavBar from './components/navbar/NavBar';
import Footer from './components/footer/Footer';
import MessageDisplay from './components/messages/MessageDisplay';
import ProtectedRoute from './components/ProtectedRoute';
// Pages
import Home from './pages/Home';
import AdvisorProfile from './pages/AdvisorProfile';
import SchoolProfile from './pages/SchoolProfile';
import Dashboard from './pages/Dashboard';
import Review from './Removing/Review';

// App component manages routing and initial django_authentication check
function App() {
    return (
        <>
            <NavBar />
            <MessageDisplay />
            <div className="content"> {/* controlled in index.css, flexbox styling */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/advisor/:advisorId" element={<AdvisorProfile />} />
                    <Route path="/advisor/:advisorId/review" element={<ProtectedRoute><Review /></ProtectedRoute>} />
                    <Route path="/school/:schoolId" element={<SchoolProfile />} />
                    <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </div>
            <Footer />
        </>
    );
}

export default App;
