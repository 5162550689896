// src/components/ReviewForm.jsx
// React and Related Libraries
import React, { useState, useEffect } from 'react';
// API and Axios Instances
import { privateAxiosInstance } from "../api/axios";
// Hooks
import useReviewForm from '../hooks/useReviewForm';
import AdvisorDescriptionSection from './review/AdvisorDescriptionSection';
import LabCultureSection from './review/LabCultureSection';
import AccessibilitySection from "./review/AccessibilitySection";
import { FormControl, FormLabel, FormHelperText, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
// Import Accordion components from MUI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { produce } from 'immer';
import lookupTable from '../utils/lookupTable';
import { set } from 'lodash';
// Utilities
import { convertToSnakeCase, convertToCamelCase } from '../utils/caseConverter';
import { eventEmitter } from '../utils/eventEmitter';
// Validations
import { validate } from '../validations/reviewValidation';
import handleErrors from '../utils/errorHandler';


// Define initialFormState outside ReviewForm component
export const initialFormState = {
    attributes: {
        recommendAdvisor: { value: null },
        positionHeld: { value: null },
        meetingFrequency: { value: null },
        graduateOnTime: { value: null },
        additionalComments: '',
        sliders: {
            expectations: 0,
            judgmentLevel: 0,
            openness: 0,
            micromanagementLevel: 0,
            meetingClarity: 0,
            feedbackQuality: 0,
            developmentOpportunities: 0,
            fundingLevel: 0,
            teamOrientationLevel: 0,
            respectInclusivityLevel: 0,
            workLifeBalanceLevel: 0,
            diversityInclusionLevel: 0,
            socialCohesionLevel: 0,
            accessibility: 0,
            responseTime: 0,
        },
    }
}

const ReviewForm = ({ advisorId, onReviewSubmit }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [reviewId, setReviewId] = useState(null);
    const [ignoreSoftWarnings, setIgnoreSoftWarnings] = useState(false);

    // Use the useReviewForm hook with initialFormState for managing form data
    const {
        formData: reviewFormData,
        setFormData: setReviewFormData,
        formErrors,
        formWarnings,
        validateReviewForm
    } = useReviewForm(initialFormState, validate, ignoreSoftWarnings);

    // Function to reset the form to its initial state
    const handleReset = () => {
        setReviewFormData(initialFormState);
        // setIsEditing(false); // Ensure form is no longer in 'edit' mode
        // setReviewId(null); // Clear any specific reviewId
        setIgnoreSoftWarnings(false);
        // You may also want to reset any additional states like form errors or warnings
    };

    // // Function to handle changes in positionHeld
    // const handlePositionChange = (event) => {
    //     setReviewFormData(prevFormData => ({
    //         ...prevFormData,
    //         positionHeld: event.target.value, // Update positionHeld within reviewFormData
    //     }));
    // };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        if (isExpanded) {
            setTimeout(() => {
                const element = document.getElementById(panel);
                if (element) {
                    const navHeight = getComputedStyle(document.documentElement).getPropertyValue('--nav-height');
                    console.log(navHeight);
                    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
                    const navHeightPixels = parseFloat(navHeight) * rootFontSize;

                    const elementRect = element.getBoundingClientRect();
                    const absoluteElementTop = elementRect.top + window.scrollY;
                    const top = absoluteElementTop - (navHeightPixels * 2);

                    window.scrollTo({
                        top: top,
                        behavior: 'smooth'
                    });
                }
            }, 300);
        }
    };

    const handleCancel = () => {
        handleReset(); // This resets the form
        // Optionally, navigate away or change the UI state as needed
        onReviewSubmit(); // If this function navigates away or closes the form, keep it
    };


   const handleChange = (event) => {
       const {name, value} = event.target; // `value` is the selected option's value

       setReviewFormData(prevFormData =>
           produce(prevFormData, draftState => {
               set(draftState, name, value); // Updates the state with the selected value
           })
       );
   };

    useEffect(() => {
        console.log('ReviewFormData updated:', reviewFormData);
    }, [reviewFormData]);

    const handleCheckboxChange = (checkboxName) => (event) => {
        const {value} = event.target;
        setReviewFormData(prevFormData => {
            const updatedValues = prevFormData[checkboxName].includes(value)
                ? prevFormData[checkboxName].filter(item => item !== value)
                : [...prevFormData[checkboxName], value];

            return {...prevFormData, [checkboxName]: updatedValues};
        });
        console.log("Updated Checkbox State:", reviewFormData[checkboxName]); // potentially misleading because setReviewFormData does not immediately update the state TODO: remove later
    };

    // Load existing review data if editing
    useEffect(() => {
        const fetchReviewData = async () => {
            try {
                // Fetch reviews for the logged-in user and the specific advisor
                const response = await privateAxiosInstance.get(`/api/v1/reviews/user-reviews/?advisor_id=${advisorId}`);
                console.log("Fetched reviews:", response.data);  // Log to check fetched data// TODO: remove later
                const userReview = response.data;
                if (userReview.length > 0) {
                    // Assuming the first review is the one we want to edit
                    const reviewToEdit = userReview[0];
                    const formattedReview = convertToCamelCase(reviewToEdit); // Assuming this utility function handles key conversion without altering the values
                    console.log("Review to edit:", formattedReview);  // Log to check the selected review// TODO: remove later
                    setReviewFormData(formattedReview);
                    setIsEditing(true);
                    setReviewId(formattedReview.id);
                    console.log("Fetched and Set Review Data:", formattedReview);
                }
            } catch (error) {
                console.error('Error fetching review data:', error);
                const errorMessage = handleErrors(error);
                // Display the errorMessage to the user
                eventEmitter.emitError('apiError', new Error(errorMessage));
            }
        };

        fetchReviewData();
    }, [advisorId, setReviewFormData]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // AdvisorID is a string at first, here we check
        console.log('Advisor ID:', advisorId, 'Type:', typeof advisorId); // TODO: remove later

        /// Convert advisorId to a number for API compatibility
        const numericAdvisorId = Number(advisorId);
        console.log('Converted Advisor ID:', numericAdvisorId, 'Type:', typeof numericAdvisorId); // TODO: remove later

        console.log("Attempting to Submit Form:", reviewFormData); // TODO: remove later
        const {isFormValid, errors, warnings} = validateReviewForm();
        console.log("Is Form Valid:", isFormValid); // TODO: remove later

        if (!isFormValid) {
            // Log and display all validation errors
            if (Object.keys(errors).length > 0) {
                console.log('Validation failed with errors:', errors);  // TODO: remove later
                Object.keys(errors).forEach(key => {
                    eventEmitter.emitError('errorMessage', errors[key]);
                });
            }

            // Emit warnings if there are any and if they haven't been ignored yet
            if (warnings.length > 0 && !ignoreSoftWarnings) {
                warnings.forEach(warning => eventEmitter.emitMessage('warningMessage', warning));
                setIgnoreSoftWarnings(true);
            }

            // Prevent form submission if there are any errors or warnings
            return;
        }

        // Assemble the data in the desired structure
        const reviewData = {
            type: "review",
            advisor: numericAdvisorId,
            attributes: {...reviewFormData.attributes},
            metadata: {
                language: 'en' // Or dynamically set based on the user's preference or browser setting
            },
        };

        // Convert the entire object to snake_case, including nested objects
        const dataForSubmission = convertToSnakeCase(reviewData);

        console.log('Final request payload:', dataForSubmission); // TODO: remove later
        console.log(`Advisor ID: ${dataForSubmission.advisor}, Type: ${typeof dataForSubmission.advisor}`); // TODO: remove later


        try {
            const apiUrl = `/api/v1/reviews/${isEditing ? reviewId : ''}`;
            const method = isEditing ? 'put' : 'post';
            console.log(`Submitting ${isEditing ? 'edit' : 'new'} review to ${apiUrl} with data:`, dataForSubmission); // TODO: remove later
            const response = await privateAxiosInstance[method](apiUrl, dataForSubmission);
            console.log('Review submission response:', response.data); // TODO: remove later

            // Handle success (e.g., display success message, navigate to another page)
            eventEmitter.emitMessage('successMessage', `Review ${isEditing ? 'updated' : 'submitted'} successfully!`);
            setIgnoreSoftWarnings(false);
            onReviewSubmit(); // Call the callback after successful submission
        } catch (error) {
            console.error('Review submission error:', error.response ? error.response.data : error); // TODO: remove later
            const errorMessage = handleErrors(error);
            eventEmitter.emitError('apiError', new Error(errorMessage));
        }
    }

    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this review?')) return;

        try {
            await privateAxiosInstance.delete(`/api/v1/reviews/${reviewId}`);
            eventEmitter.emitMessage('successMessage', 'Review deleted successfully!');
            onReviewSubmit(); // Call the callback after successful deletion
        } catch (error) {
            const errorMessage = handleErrors(error);
            // Display the errorMessage to the user
            eventEmitter.emitError('apiError', new Error(errorMessage));
        }
    };


    // Helper function to generate radio buttons from lookupTables
    const generateRadioButtons = (category) => {
        return Object.entries(lookupTable[category]).map(([value, label]) => (
            <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
        ));
    };


    return (
        <form onSubmit={handleSubmit} noValidate>

            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" style={{paddingTop: '2rem'}}>Position held while working with advisor</FormLabel>
                <RadioGroup
                    aria-label="positionHeld"
                    name="attributes.positionHeld.value"
                    value={reviewFormData.attributes.positionHeld?.value || ''}
                    onChange={handleChange}
                >
                    {generateRadioButtons('positionHeld')}
                </RadioGroup>
            </FormControl>

            <Accordion id="advisorDescription" onChange={handleAccordionChange('advisorDescription')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>How would you describe this advisor?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AdvisorDescriptionSection
                        formData={reviewFormData}
                        handleChange={handleChange}
                        handleCheckboxChange={handleCheckboxChange}
                        formErrors={formErrors}
                        formWarnings={formWarnings}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion id="labCulture" onChange={handleAccordionChange('labCulture')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>What was the lab culture like?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <LabCultureSection
                        formData={reviewFormData}
                        handleChange={handleChange}
                        handleCheckboxChange={handleCheckboxChange}
                        formErrors={formErrors}
                        formWarnings={formWarnings}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion id="accessibility" onChange={handleAccordionChange('accessibility')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>How accessible is your advisor?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AccessibilitySection
                        formData={reviewFormData}
                        handleChange={handleChange}
                        handleCheckboxChange={handleCheckboxChange}
                        formErrors={formErrors}
                        formWarnings={formWarnings}
                    />
                </AccordionDetails>
            </Accordion>

            {/*// Radio group for 'graduateOnTime' category*/}
            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" style={{paddingTop: '2rem'}}>Do people graduate on time in your
                    lab?</FormLabel>
                <RadioGroup
                    aria-label="graduateOnTime"
                    name="attributes.graduateOnTime.value"
                    value={reviewFormData.attributes.graduateOnTime?.value || ''}
                    onChange={handleChange}
                >
                    {generateRadioButtons('graduateOnTime')}
                </RadioGroup>
                {formErrors.graduateOnTime && <p className="error-message">{formErrors.graduateOnTime}</p>}
            </FormControl>

            {/*// Radio group for 'recommendAdvisor' category*/}
            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" style={{paddingTop: '2rem'}}>Would you recommend this advisor?</FormLabel>
                <RadioGroup
                    aria-label="recommendAdvisor"
                    name="attributes.recommendAdvisor.value"
                    value={reviewFormData.attributes.recommendAdvisor?.value || ''}
                    onChange={handleChange}
                >
                    {generateRadioButtons('recommendAdvisor')}
                </RadioGroup>
                {formErrors.recommendAdvisor && <p className="error-message">{formErrors.recommendAdvisor}</p>}
            </FormControl>

            <FormControl fullWidth error={Boolean(formErrors.additionalComments)}>
                <FormLabel component="legend" style={{paddingTop: '2rem'}}>Additional Comments</FormLabel>
                <TextareaAutosize
                    aria-label="additional comments"
                    minRows={3}
                    name="attributes.additionalComments"
                    placeholder="Free space to more fully express your thoughts..."
                    value={reviewFormData.attributes.additionalComments}
                    onChange={handleChange}
                    style={{width: '100%'}}
                />
                {formErrors.additionalComments && <FormHelperText>{formErrors.additionalComments}</FormHelperText>}
            </FormControl>

            <div>
                <button type="submit">{isEditing ? 'Save Edits' : 'Submit Review'}</button>
                {isEditing && (
                    <button type="button" onClick={handleDelete}>Delete Review</button>
                )}
                {/* Reset and Cancel Buttons */}
                <button type="button" onClick={handleReset}>Clear Review</button>
                <button type="button" onClick={handleCancel}>Cancel & Exit</button>
            </div>
        </form>
    );
};

export default ReviewForm;
