// src/components/SearchableAdvisorsTable.jsx
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import AdvisorTable from './advisors/AdvisorsTable';
// import styles from "./search/SearchBar.module.css";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Paper,
    TablePagination,
    TextField,
} from '@mui/material';

const SearchableAdvisorTable = ({ advisors, requestSort }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const filteredAdvisors = useMemo(() => {
        return searchTerm
            ? advisors.filter(advisor =>
                advisor.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            : advisors;
    }, [advisors, searchTerm]);


        // Paginate filtered advisors
    const paginatedAdvisors = useMemo(() => {
        return filteredAdvisors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [filteredAdvisors, page, rowsPerPage]);

    return (
        <Box>
            <TextField
                label="Search available advisors"
                variant="outlined"
                value={searchTerm}
                 onChange={e => {
                    setSearchTerm(e.target.value);
                    setPage(0); // Reset pagination to the first page
                }}
                margin="normal"
                fullWidth
            />
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {/* Add your table headers here */}
                            <TableCell>Name</TableCell>
                            <TableCell>Area of Research</TableCell>
                            {/* Add more headers as needed */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedAdvisors.map((advisor) => (
                            <TableRow key={advisor.id}>
                                 <TableCell>
                                    <Link to={`/advisor/${advisor.id}/`} style={{ color: 'inherit' }}>
                                        {advisor.name}
                                    </Link>
                                </TableCell>
                                <TableCell>{advisor.research_area}</TableCell>
                                {/* Add more cells as needed */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredAdvisors.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};

SearchableAdvisorTable.propTypes = {
  advisors: PropTypes.array.isRequired,
  requestSort: PropTypes.func.isRequired,
};

export default SearchableAdvisorTable;