// ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
// import lightThemeOptions from '../themes/lightTheme';
// import darkThemeOptions from '../themes/darkTheme';
import { darkColors, lightColors } from '../themes/colors';

const ThemeContext = createContext();

// Define the getTheme function to dynamically generate the theme
const getTheme = (mode) => createTheme({
  palette: {
    mode, // 'light' or 'dark'
  },
  // Additional theme customizations can go here
});

export const ThemeProvider = ({ children }) => {
  const themeKey = 'theme';
  // Determine the initial theme based on localStorage or system preference
  const initialTheme = localStorage.getItem(themeKey) || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
  const [mode, setMode] = useState(initialTheme);

  // Generate the MUI theme based on the current mode
  const theme = getTheme(mode);

  // const muiTheme = createTheme(theme === 'light' ? lightThemeOptions : darkThemeOptions);

  // Function to toggle between light and dark modes
  const toggleTheme = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem(themeKey, newMode);
  };

  // const toggleTheme = () => {
  //   const newTheme = theme === 'light' ? 'dark' : 'light';
  //   setTheme(newTheme);
  //   localStorage.setItem(themeKey, newTheme);
  // };

  // // Update meta theme color based on current MUI theme
  // useEffect(() => {
  //   const metaThemeColor = document.querySelector('meta[name=theme-color]');
  //   if (metaThemeColor) {
  //     metaThemeColor.setAttribute('content', muiTheme.palette.primary.main);
  //   }
  // }, [muiTheme]);


  // Update CSS variables based on the theme
  useEffect(() => {
    const themeColors = mode === 'light' ? lightColors : darkColors;
    const root = document.documentElement;
    Object.entries(themeColors).forEach(([key, value]) => {
      const cssVarName = `--${key.replace(/([A-Z])/g, "-$1").toLowerCase()}`; // Convert camelCase to kebab-case
      root.style.setProperty(cssVarName, value);
    });
  }, [mode]);

  return (
      // <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
      // <MUIThemeProvider theme={muiTheme}>
      <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <MUIThemeProvider theme={theme}>
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

// Custom hook to use the theme context
export const useTheme = () => useContext(ThemeContext);