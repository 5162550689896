/**
 * src/contexts/AuthContext.js
 * (Context and State Management)
 *
 * Role: Manages and provides the global state related to user django_authentication across the entire application.
 *
 * Key Responsibilities:
 * - Unified State Management: Maintains an django_authentication state (`authState`) with user authorization status (`isAuthorized`) and schoolID.
 * - Google OAuth Process: Handles the Google OAuth process for user django_authentication and retrieves schoolID information.
 * - Logout Management: Offers a function (`logoutUser`) to handle user logout, removing the Google idToken and resetting `authState`.
 * - Session Persistence: Uses sessionStorage to persist the Google idToken across sessions, ensuring a continuous user experience.
 * - Error Handling: Manages django_authentication-related errors, particularly during the OAuth process.
 *
 * Usage Scenarios:
 * - Private Route Protection: Uses `authState` to ensure parts of the application are accessible only to authenticated users.
 * - State Accessibility: Allows any component in the application to access and respond to changes in the global django_authentication state.
 */
//src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {useNavigate} from "react-router-dom";
import authService from '../api/authService';

// Define the shape of your context's value
const defaultAuthContextValue = {
  authState: {
    isAuthorized: false,
    school: null,
    loading: false,
    error: null,
  },
  setAuthState: (newState) => {},             // Placeholder function
  logoutUser: async () => {},   // Placeholder async function
};

export const AuthContext = createContext(defaultAuthContextValue);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(defaultAuthContextValue.authState);
  const [initialAuthCheckDone, setInitialAuthCheckDone] = useState(false);
  const navigate = useNavigate();

  const updateAuthState = useCallback((newState) => {
    console.log("Current authState before update:", authState);
    console.log("New state to be set:", newState);
    setAuthState((prevState) => ({
      ...prevState,
      ...newState,
    }));
    console.log("Updated authState:", {...authState, ...newState});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutUser = useCallback(() => {
    authService.logout();
    setAuthState({isAuthorized: false, school: null, loading: false, error: null});
    navigate('/');
  }, [navigate]);

  const getIdToken = useCallback(() => {
    return authService.getIdToken(); // Function to get ID token from authService
  }, []);


  // Call initializeAuthState when the component mounts
  useEffect(() => {
    const storedToken = authService.getIdToken();
    // console.log("Token retrieved from session storage:", storedToken);
    if (storedToken) {
      authService.verifyIdTokenWithBackend(storedToken)
          .then(userData => {
            if (userData.success) {
              setAuthState({
                isAuthorized: true,
                school: userData.data.school,
                loading: false,
                error: null,
              });
            } else {
              console.error("Token verification failed: ", userData.error);
              logoutUser();
            }
          })
          .catch(error => {
            console.error("Error verifying token: ", error);
            logoutUser();
          })
          .finally(() => setInitialAuthCheckDone(true)); // Set true after checking
    } else {
      setAuthState(prevState => ({...prevState, loading: false}));
      setInitialAuthCheckDone(true); // Set true if no token
    }
  }, [logoutUser]);

  return (
      <AuthContext.Provider value={{ authState, updateAuthState, logoutUser, getIdToken, initialAuthCheckDone }}>
        {children}
      </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
